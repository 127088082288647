import {
  BigTitle,
  Box,
  Header,
  CheckList,
  Video,
  VideoImage,
  SmallTitle,
  UserInterface,
  AdaptiveImage,
  SliderLogo,
  InputButton,
} from '../'
import React, { useState } from 'react'
import styled from 'styled-components'
import { HeaderStyled, HeaderContent } from '../Headers/Header'
import { KillerArgument } from './Header'

/**
 * Style
 */

const HeaderStyle = styled(props => <Header {...props} />)`
  /* Mobile-first (default styles) */
  height: unset;
  min-height: unset;
  background-color: transparent !important;
  margin-bottom: 0px !important;

  .image img {
    vertical-align: bottom !important;
  }

  .videoImageWrapper {
    img {
      border-radius: 58% 45% 21% 15% / 73% 55% 50% 32%;
    }
  }

  /* Adjustments for larger screens */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    min-height: 560px;
    height: 560px;

    .videoImageButton {
      left: calc(50% + 10px);
      top: calc(50% - 10px);
    }

    .image {
      position: absolute;
      top: -253px;
      left: -108px;
    }

    .image img {
      width: 120%;
      height: 110%;
      max-width: unset;
      max-height: unset;
    }
  }

  @media screen and (min-width: ${UserInterface.breakpoints
      .extraSmallMobile}) and (max-width: ${UserInterface.breakpoints
      .smallMobile}) {
    .videoImageWrapper {
      margin-top: 1rem;
      height: 334px;
    }
  }

  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    .videoImageButton {
      top: calc(50% - 40px);
    }
  }

  @media screen and (max-width: ${UserInterface.breakpoints.extraSmallMobile}) {
    .videoImageWrapper {
      height: 334px;
      margin-top: 1rem;

      .image img {
        width: 100%;
        height: 100%;
        min-height: 280px;
        max-width: unset;
        max-height: unset;
      }
    }
  }
`

const Logo = styled(props => <AdaptiveImage {...props} />)`
  height: 40 px;
  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    height: 50px;
  }
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    height: 60px;
  }
`

const BoxHeader = styled(props => <Box {...props} />)`
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;

  /* Adjustments for larger screens */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`

const HeaderHomeContainer = styled.div`
  background: linear-gradient(
    to bottom,
    ${UserInterface.colors.clearBlue},
    ${UserInterface.colors.white} 11.765rem
  );

  ${HeaderStyled} {
    overflow: hidden;
    margin-bottom: unset;
  }

  ${HeaderContent} {
    height: unset;
    min-height: unset;

    /* Adjustments for larger screens */
    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      min-height: 560px;
      height: 560px;
    }
  }
`

const CheckListStyled = styled(props => <CheckList {...props} />)`
  height: 7rem;

  .check-list__item {
    margin-left: 0;
    margin-top: 0rem;
    margin-bottom: 0.8rem;
  }
`

const VideoStyled = styled(props => <Video {...props} />)`
  height: 0;
  width: 0;
`

const SliderContainer = styled.div`
  padding: 22px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  height: 137px;

  /* Mobile-first (default styles) */
  flex-direction: column;

  h2 {
    padding-bottom: 1rem;
  }

  /* Adjustments for larger screens */
  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    flex-direction: row;
    h2 {
      padding-bottom: unset;
    }
  }
`

const BigTitleStyled = styled(props => <BigTitle {...props} />)`
  font-size: 2.4rem !important;
  line-height: 3.2rem !important;
  margin-bottom: 1.5rem;

  /* Adjustments for larger screens */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    font-size: ${({ isSpringly }) =>
      isSpringly ? '2.8rem' : '3rem'} !important;
    line-height: ${({ isSpringly }) =>
      isSpringly ? '3.6rem' : '4.2rem'} !important;
  }
`

const NoticeText = styled(props => <SmallTitle {...props} />)`
  margin: 1.4rem 0;
`

const VideoImageContainer = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 334px;

  /* Adjustments for larger screens */

  @media screen and (min-width: ${UserInterface.breakpoints
      .smallMobile}) and (max-width: ${UserInterface.breakpoints.mobile}) {
    height: 445px;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    display: block;
    height: unset;
  }
`

/**
 * Component
 */
const HeaderHome = ({
  image,
  title = '',
  buttonHref,
  hubspotId,
  killerArgument = null,
  videoId,
  translations,
  noticeText,
  checkListContent,
  imageUrlForNotice,
}) => {
  const [wistiaPopoverVisible, setWistiaPopoverVisible] = useState(false)
  /**
   * Handle click events on VideoButtonWrapper component
   */
  const handleVideoButtonWrapperClick = () => {
    // necessary to force rerendering of the <Video> component, Wistia "popoverhide" event being unreliable in the context of React

    const existingScript = document.getElementById('wistia')

    if (!existingScript) {
      const wistiaScript = document.createElement('script')
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js'
      wistiaScript.id = 'wistia'
      document.body.appendChild(wistiaScript)
    }

    setWistiaPopoverVisible(false)
    setTimeout(() => {
      setWistiaPopoverVisible(true)
    }, 100)
  }
  return (
    <HeaderHomeContainer>
      <HeaderStyle translations={translations}>
        <BoxHeader align="center" width={0.5}>
          <BigTitleStyled
            color="blue"
            align="left"
            tag="h1"
            isSpringly={process.env.GATSBY_CONTEXT_BRAND === 'SPRINGLY'}
          >
            {title}
          </BigTitleStyled>
          <CheckListStyled content={checkListContent} />
          <InputButton
            translations={translations}
            href={buttonHref}
            hubspotId={process.env.GATSBY_HUBSPOT_ID ?? hubspotId}
            className="shadow"
          />
          {killerArgument && (
            <KillerArgument color="middleGrey">{killerArgument}</KillerArgument>
          )}
        </BoxHeader>
        <VideoImageContainer width={0.5}>
          <VideoImage
            image={image}
            onClick={handleVideoButtonWrapperClick}
            videoId={videoId}
            visibleByDefault
          />
        </VideoImageContainer>
      </HeaderStyle>
      <NoticeText font="poppins" align="center" color="middleGrey">
        {noticeText.text}
      </NoticeText>
      <SliderContainer>
        <SliderLogo
          changeInMobile={false}
          slidesToShow={4}
          withArrows={false}
          autoplay
          withDots={false}
        >
          {imageUrlForNotice.map((imageUrl, index) => (
            <Logo
              key={index}
              src={imageUrl}
              alt="img"
              maxDpi={1}
              visibleByDefault
            />
          ))}
        </SliderLogo>
      </SliderContainer>
      {videoId && wistiaPopoverVisible && (
        <VideoStyled
          scriptLoadedFromClick
          headerHomeVideo
          videoId={videoId}
          wistiaParameters={[
            'popover=true',
            'popoverShowOnLoad=true',
            'popoverAnimateThumbnail=true',
          ]}
        />
      )}
    </HeaderHomeContainer>
  )
}

export default HeaderHome
