import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { SectionStyled } from './Sections/Section'
import { UserInterface } from '.'
import classNames from 'classnames'
import { TClassName } from './type'

/**
 * Style
 */

const underlineIntrinsicWidth = '8'
const underlineCapWidth = '0.4rem'

const blueUnderlineColor = '#6f97f5'
const blueCapImageLeft =
  'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA1IDgiIGZpbGw9Im5vbmUiPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj4KPHBhdGggZD0iTTQgNEgxNTIiIHN0cm9rZT0iIzZGOTdGNSIgc3Ryb2tlLXdpZHRoPSI4IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMCI+CjxyZWN0IHdpZHRoPSI1IiBoZWlnaHQ9IjgiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+)'
const bluecCpImageRight =
  'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA1IDgiIGZpbGw9Im5vbmUiPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj4KPHBhdGggZD0iTTEgNEgtMTQ3IiBzdHJva2U9IiM2Rjk3RjUiIHN0cm9rZS13aWR0aD0iOCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI4IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPg==)'

const turquoiseUnderlineColor = '#87dfd5'
const turquoiseCapImageLeft =
  'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA1IDgiIGZpbGw9Im5vbmUiPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj4KPHBhdGggZD0iTTQgNEgxNTIiIHN0cm9rZT0iIzg3REZENSIgc3Ryb2tlLXdpZHRoPSI4IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMCI+CjxyZWN0IHdpZHRoPSI1IiBoZWlnaHQ9IjgiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+ICA=)'
const turquoiseCapImageRight =
  'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA1IDgiIGZpbGw9Im5vbmUiPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj4KPHBhdGggZD0iTTEgNEgtMTQ3IiBzdHJva2U9IiM4N0RGRDUiIHN0cm9rZS13aWR0aD0iOCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI4IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPg==)'

const HighlightCommonCss = css`
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    --underline-width: 4;
    --underline-offset-y: -0.3rem;
  }

  --underline-width: 5;
  --underline-offset-y: -0.4rem;
  display: inline;
  background-repeat: no-repeat;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`

export const HighlightStyled = styled.span`
  ${HighlightCommonCss}

  --underline-width-scale: calc(
      var(--underline-width) / ${underlineIntrinsicWidth}
    );

  background-image: linear-gradient(
      180deg,
      ${turquoiseUnderlineColor},
      ${turquoiseUnderlineColor}
    ),
    ${turquoiseCapImageLeft}, ${turquoiseCapImageRight};

  background-position-x: calc(
      ${underlineCapWidth} * var(--underline-width-scale)
    ),
    0, 100%;
  background-position-y: calc(100% - var(--underline-offset-y) * -1);
  background-size:
    calc(100% - 0.4rem * var(--underline-width-scale) * 2)
      calc(var(--underline-width) * 0.1rem),
    auto calc(var(--underline-width) * 0.1rem),
    auto calc(var(--underline-width) * 0.1rem);

  ${SectionStyled}.section--blue & {
    ${HighlightCommonCss}

    --underline-width-scale: calc(
      var(--underline-width) / ${underlineIntrinsicWidth}
    );

    background-image: linear-gradient(
        180deg,
        ${blueUnderlineColor},
        ${blueUnderlineColor}
      ),
      ${blueCapImageLeft}, ${bluecCpImageRight};

    background-position-x: calc(
        ${underlineCapWidth} * var(--underline-width-scale)
      ),
      0, 100%;
    background-position-y: calc(100% - var(--underline-offset-y) * -1);
    background-size:
      calc(100% - 0.4rem * var(--underline-width-scale) * 2)
        calc(var(--underline-width) * 0.1rem),
      auto calc(var(--underline-width) * 0.1rem),
      auto calc(var(--underline-width) * 0.1rem);
  }
  ${SectionStyled}:not(.section--blue) & {
    ${HighlightCommonCss}

    ${turquoiseUnderlineColor}: #87dfd5;
    --turquoise-cap-image-left: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA1IDgiIGZpbGw9Im5vbmUiPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj4KPHBhdGggZD0iTTQgNEgxNTIiIHN0cm9rZT0iIzg3REZENSIgc3Ryb2tlLXdpZHRoPSI4IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMCI+CjxyZWN0IHdpZHRoPSI1IiBoZWlnaHQ9IjgiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+ICA=);
    --turquoise-cap-image-right: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA1IDgiIGZpbGw9Im5vbmUiPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj4KPHBhdGggZD0iTTEgNEgtMTQ3IiBzdHJva2U9IiM4N0RGRDUiIHN0cm9rZS13aWR0aD0iOCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI4IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPg==);

    --underline-width-scale: calc(
      var(--underline-width) / ${underlineIntrinsicWidth}
    );

    background-image: linear-gradient(
        180deg,
        ${turquoiseUnderlineColor},
        ${turquoiseUnderlineColor}
      ),
      ${turquoiseCapImageLeft}, ${turquoiseCapImageRight};

    background-position-x: calc(
        ${underlineCapWidth} * var(--underline-width-scale)
      ),
      0, 100%;
    background-position-y: calc(100% - var(--underline-offset-y) * -1);
    background-size:
      calc(100% - 0.4rem * var(--underline-width-scale) * 2)
        calc(var(--underline-width) * 0.1rem),
      auto calc(var(--underline-width) * 0.1rem),
      auto calc(var(--underline-width) * 0.1rem);
  }

  &.size--small {
    --underline-offset-y: -0.2rem !important;
    --underline-width: 4 !important;
  }
`

interface IHighlight {
  children: React.ReactNode
  size?: 'small'
  className?: TClassName
}

/**
 * Component
 */
const Highlight: FC<IHighlight> = ({ children, size, className }) => (
  <HighlightStyled className={classNames(className, `size--${size}`)}>
    {children}
  </HighlightStyled>
)

Highlight.displayName = 'Highlight' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default Highlight
