import React from 'react'
import reactElementToJSXString from 'react-element-to-jsx-string'
import { Flex, Box, UserInterface } from '.'

import styled from 'styled-components'

const PrismWrapper = styled(props => <Flex {...props} />)`
  background-color: ${props => UserInterface.colors[props.backgroundColor]};
  color: ${props => UserInterface.colors[props.textColor]};
  border: ${props =>
    props.displayBorder ? '2px solid rgb(245, 242, 240)' : 'none'};
  padding: 20px 0;
  margin: 20px auto;
  width: 100%;
`

const PrismStyled = styled.div`
  margin: ${props => (props.fullscreen ? '20px 0 0' : '0')} !important;
`

const SyntaxHighlighter = ({
  backgroundColor = 'white',
  textColor = 'darkGrey',
  displayBorder = true,
  children,
  fullscreen = false,
}) => {
  const options = {
    showDefaultProps: false,
    filterProps: ['ref', 'key'],
  }

  const getReactElement = () => {
    if (children.length > 1) {
      const reactElement = []
      Object.values(children).forEach(child => {
        reactElement.push(reactElementToJSXString(child, options))
      })
      return reactElement.join('\n')
    }
    return reactElementToJSXString(children, options)
  }

  const getWidth = () => (fullscreen ? 1 : 1 / 2)

  return (
    <PrismWrapper
      flexDirection={fullscreen ? 'column' : 'row'}
      alignItems="center"
      backgroundColor={backgroundColor}
      textColor={textColor}
      displayBorder={displayBorder}
    >
      <Box width={getWidth()}>{children}</Box>
      <Box width={getWidth()}>
        <PrismStyled fullscreen={fullscreen} language="javascript">
          {getReactElement()}
        </PrismStyled>
      </Box>
    </PrismWrapper>
  )
}

SyntaxHighlighter.displayName = 'SyntaxHighlighter' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default SyntaxHighlighter
