import React, { FC } from 'react'
import styled from 'styled-components'
import { Svg, InputButton } from '../../..'
import { Wrapper, StyledHeader, Title, IconWrap, SubNews } from './styled.js'
import { INewsletter } from '../type'

const SvgStyled = styled(props => <Svg {...props} />)``
const Newsletter: FC<INewsletter> = ({
  hubspotId,
  hubspotCode,
  mobile,
  title,
  subtitle,
  buttonHref,
  translations,
}) => (
  <Wrapper mobile={mobile}>
    <StyledHeader>
      <IconWrap>
        <SvgStyled
          src="components/blog/icons/blog-mail"
          width="40px"
          alt="mail icon"
        />
      </IconWrap>
      <Title>{title}</Title>
    </StyledHeader>
    <SubNews>{subtitle}</SubNews>

    <InputButton
      className="pt-small"
      newsletter
      translations={translations}
      href={buttonHref}
      hubspotId={process.env.GATSBY_HUBSPOT_ID ?? hubspotId}
      hubspotCode={hubspotCode}
      encodeEmail={false}
    />
  </Wrapper>
)

export default Newsletter
