// Remove favorites code
export const cleanFavoritesSlugs = post => {
  const favoritesSlugs = post.body.match(/{{favorites\(([\w\d\-,#/ ]+)\)}}/gi)

  if (favoritesSlugs && favoritesSlugs.length) {
    favoritesSlugs.map(favoritesSlug => {
      post.body = post.body.replace(favoritesSlug, '')
      return true
    })
  }
  return post
}

// Remove faq code
export const cleanFaq = post => {
  const faq = post.body.match(/{{faq\((.+)\)}}/gm)
  if (faq && faq.length) {
    faq.map(faqItem => {
      post.body = post.body.replace(faqItem, '')
      return true
    })
  }
  return post
}

// Get faq items
export const getFaqItems = faq => {
  if (!faq.length) {
    return null
  }
  const faqArray = JSON.parse(faq)
  return faqArray.map(faqItem => ({
    title: faqItem.title,
    text: faqItem.description,
  }))
}

// Remove faq2 code
// faq2(title, description, lien)
export const cleanFaq2 = post => {
  const faq2 = post.body.match(/{{faq2\("(.+)","(.+)","(.+)"\)}}/gi)
  if (faq2 && faq2.length) {
    faq2.map(faq2Item => {
      post.body = post.body.replace(faq2Item, '')
      return true
    })
  }
  return post
}

export const getFaq2Items = faq2 => {
  if (!faq2.length) {
    return null
  }
  return faq2.map(faq2Item => {
    const faq2ItemSplitted = faq2Item.split('","')
    return {
      title: faq2ItemSplitted[0].trim(),
      text: faq2ItemSplitted[1].trim(),
      link: faq2ItemSplitted[2].trim(),
    }
  })
}

export const recommendedSlugs = body =>
  body.match(/{{recommended\(([\w\d\-,#/ ]+)\)}}/gi)

export const cleanBody = (body, blogPath, hubspotCta) => {
  // Clean body links referencing other posts
  body = body.split(`href="/`).join(`href="${blogPath}/`)
  body = body.replace(/data-src/g, 'src')

  // clean for mobile / desktop
  body = body.replace(/{{only-mobile}}/gi, '<div class="hidden-desktop">')
  body = body.replace(/{{\/only-mobile}}/gi, '</div>')
  body = body.replace(/{{only-desktop}}/gi, '<div class="hidden-mobile">')
  body = body.replace(/{{\/only-desktop}}/gi, '</div>')

  // Remove recommended code
  const slugs = recommendedSlugs(body)

  if (slugs && slugs.length) {
    slugs.map(recommendedSlug => {
      body = body.replace(recommendedSlug, '')
      return true
    })
  }

  // Check if internal links have a trailing slash
  const searchStr = 'href="/'
  const indices = getIndicesOf(searchStr, body)
  const unslashedLinks = []

  for (let indice of indices) {
    const linkEnd =
      body.substring(indice + searchStr.length).indexOf('"') +
      indice +
      searchStr.length
    const link = body.substring(indice + searchStr.length, linkEnd)

    if (link.slice(-1) !== '/') {
      unslashedLinks.push(link)
    }
  }

  // Add a trailing slash if needed
  for (let unslashedLink of unslashedLinks) {
    body = body.replace(unslashedLink, `${unslashedLink}/`)
  }

  // inject CTAs
  const ctas = body.match(/{{cta\(([\w\d\-',]+)\)}}/gi)
  if (ctas && ctas.length) {
    ctas.map(cta => {
      const uuid = getCtaUuid(cta)
      body = body.replace(cta, getCtaCode(hubspotCta.code, uuid))
      return true
    })
  }

  // Link obfuscation
  const obfuscatedHrefs = body.match(/href="##([-a-zA-Z0-9()@:%_+.~#?&/=]*)"/gi)
  if (obfuscatedHrefs && obfuscatedHrefs.length) {
    obfuscatedHrefs.map(obfuscatedHref => {
      const href = obfuscatedHref.split('"')[1].replace('##', '')
      body = body.replace(obfuscatedHref, `data-64="${btoa(href)}"`)
      return true
    })
  }

  return body
}

// CTA
export const getCtaCode = (code, uuid) =>
  `
  <div style='display:flex;width:100%;justify-content:center;'>
    <span class="hs-cta-wrapper" id="hs-cta-wrapper-${uuid}">
      <span class="hs-cta-node hs-cta-${uuid}" id="hs-cta-${uuid}">
        <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
        <a href="https://cta-redirect.hubspot.com/cta/redirect/${code}/${uuid}" target="_blank">
          <img loading="lazy" alt="cta-image" class="hs-cta-img" id="hs-cta-img-${uuid}" style="border-width:0;" src="https://no-cache.hubspot.com/cta/default/${code}/${uuid}.png"/>
        </a>
      </span>
      <script defer type="text/javascript"> window?.hbspt.cta.load(${code}, '${uuid}', {}); </script>
    </span>
  </div>
`

export const getCtaUuid = cta => {
  const start = cta.indexOf("('") + 2
  const end = cta.indexOf("')")
  const ctaContent = cta.substring(start, end)
  const ctaArray = ctaContent.split("','")
  return ctaArray[0]
}

// getIndicesOf (check for internal links have a trailing slash)

const getIndicesOf = (searchStr, str, caseSensitive) => {
  const searchStrLen = searchStr.length
  if (searchStrLen === 0) {
    return []
  }

  let startIndex = 0
  let index
  const indices = []
  if (!caseSensitive) {
    str = str.toLowerCase()
    searchStr = searchStr.toLowerCase()
  }

  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index)
    startIndex = index + searchStrLen
  }
  return indices
}
