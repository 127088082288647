import React, { FC, useContext } from 'react'
import { ButtonLink as ButtonComponent } from '..'
import classNames from 'classnames'
import { TButtonPrimary } from './type'
import { AppContext } from '../../providers/SectionProvider'
import styled from 'styled-components'
import { getPrimaryStyles } from './utils'

const Button = styled(props => <ButtonComponent {...props} />)`
  ${({ sectionBackground, styles }) =>
    sectionBackground &&
    `button {
      color: ${styles.color} !important;
      background-color: ${styles.backgroundColor} !important;
      border: ${styles.border} !important;
      &:hover {
        color: ${styles.hover.color} !important;
        border: ${styles.hover.border} !important;
        background-color: ${styles.hover.backgroundColor} !important;
      }
    }`}}
`
/**
 * Component
 */
const ButtonPrimary: FC<TButtonPrimary> = ({
  align,
  id,
  children,
  className,
  href,
  nofollow,
  target,
  size,
  onClick,
  margin,
}) => {
  const Context = useContext(AppContext)

  if (Context === undefined) {
    return null
  }

  const styles = getPrimaryStyles(Context.sectionBackground)

  return (
    <Button
      id={id}
      sectionBackground={Context.sectionBackground}
      align={align}
      size={size}
      margin={margin}
      href={href}
      target={target}
      className={classNames('button', 'button--type-link', className)}
      onClick={onClick}
      nofollow={nofollow}
      styles={styles}
    >
      {children}
    </Button>
  )
}

ButtonPrimary.displayName = 'ButtonPrimary' // displayName is used by storybook for react-element-to-jsx-string display

export default ButtonPrimary
