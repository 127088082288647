import { createContext, useContext } from 'react'
import { EBackground } from 'src/styles/type'

interface IContext {
  sectionBackground: EBackground
}

export const AppContext = createContext<IContext | undefined>(undefined)

export const useSectionBackground = () => useContext(AppContext)
