import React, { FC } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import { EImageType, IAdaptiveImage } from './type'

const getRoundShape = (roundShape: string) => {
  switch (roundShape) {
    case 'CIRCLE':
      return 'border-radius: 50%'
    case 'ROUNDED':
      return 'border-radius: 20px'
    case 'BLOB':
      return 'border-radius: 58% 45% 21% 15% / 73% 55% 50% 32%;'
    default:
      return ''
  }
}

interface IImageType extends React.HTMLAttributes<HTMLDivElement> {
  align?: string
  width?: string
  height?: string
  inline?: string
  roundShape?: string
}

/**
 * Style
 */
const ImageWrapper = styled.div<IImageType>`
  text-align: ${({ align }) => align};
  margin: auto;
  ${({ inline }) => inline && 'display: inline-block; vertical-align:middle;'}
`

const ImageStyledFull = styled.img<
  React.ImgHTMLAttributes<HTMLImageElement> & { roundShape?: string }
>`
  width: 100% !important;
  height: 100% !important;
  &,
  .slick-slides & {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    width: auto;
    height: auto;
    ${({ roundShape }) => roundShape && getRoundShape(roundShape)}
  }
`

const ImageStyled = styled.img<
  React.ImgHTMLAttributes<HTMLImageElement> & { roundShape?: string }
>`
  &,
  .slick-slide & {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    ${({ roundShape }) => roundShape && getRoundShape(roundShape)}
  }
  .slick-slide & {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`
/**
 * Component
 */
const AdaptiveImage: FC<IAdaptiveImage> = ({
  src,
  className,
  alt = '',
  type = EImageType.PNG,
  align = 'center',
  width = '',
  height = '',
  maxDpi = 2,
  visibleByDefault = false,
  inline,
  roundShape = 'NONE',
  id,
  isFullSize = false,
}) => {
  const sizeProps = {
    width: '',
    height: '',
  }
  if (width) {
    sizeProps.width = width
  }
  if (height) {
    sizeProps.height = height
  }

  return (
    <>
      {visibleByDefault && (
        <Helmet>
          <link
            rel="preload"
            as="image"
            href={`${process.env.GATSBY_CDN_HOST}/${src}.webp`}
            imageSrcSet={`${process.env.GATSBY_CDN_HOST}/${src}-2x.webp x2`}
            media="(min-width: 500px)"
          />
        </Helmet>
      )}
      <ImageWrapper
        width={width}
        align={align}
        height={height}
        className={classNames('image', className)}
        inline={inline}
        id={id}
      >
        <picture>
          <source
            type="image/webp"
            media="(max-width: 500px)"
            srcSet={`${process.env.GATSBY_CDN_HOST}/${src}-mobile.webp, ${process.env.GATSBY_CDN_HOST}/${src}-mobile-2x.webp 2x`}
          />
          <source
            type="image/webp"
            media="(min-width: 500px)"
            srcSet={
              `${process.env.GATSBY_CDN_HOST}/${src}.webp` +
              (maxDpi >= 2
                ? `, ${process.env.GATSBY_CDN_HOST}/${src}-2x.webp 2x`
                : '')
            }
          />
          <source
            media="(max-width: 500px)"
            srcSet={`${process.env.GATSBY_CDN_HOST}/${src}-mobile.${type}, ${process.env.GATSBY_CDN_HOST}/${src}-mobile-2x.${type} 2x`}
          />
          <source
            media="(min-width: 500px)"
            srcSet={
              `${process.env.GATSBY_CDN_HOST}/${src}.${type}` +
              (maxDpi >= 2
                ? `, ${process.env.GATSBY_CDN_HOST}/${src}-2x.${type} 2x`
                : '')
            }
          />
          {isFullSize ? (
            <ImageStyledFull
              loading="eager"
              src={`${process.env.GATSBY_CDN_HOST}/${src}.${type}`}
              alt={alt}
              roundShape={roundShape}
              {...sizeProps}
            />
          ) : (
            <ImageStyled
              loading="eager"
              src={`${process.env.GATSBY_CDN_HOST}/${src}.${type}`}
              alt={alt}
              roundShape={roundShape}
              {...sizeProps}
            />
          )}
        </picture>
      </ImageWrapper>
    </>
  )
}

AdaptiveImage.displayName = 'AdaptiveImage'
export default AdaptiveImage
