import React from 'react'
import styled, { css } from 'styled-components'
import { Box } from '@rebass/grid'
import { UserInterface } from '.'
import getDisplayName from 'react-display-name'
import { EBoxAlign, IBoxCustom } from './type'
import classNames from 'classnames'

/**
 * Style
 */
const setBoxOverflow = (
  {
    overflowLeft,
    overflowRight,
  }: { overflowLeft?: boolean; overflowRight?: boolean },
  overflowValue: number
) => {
  if (overflowLeft) {
    return `
      width: calc(100% + ${overflowValue}px);
      margin-left: -${overflowValue}px;
    `
  } else if (overflowRight) {
    return `
      width: calc(100% + ${overflowValue}px);
      margin-right: -${overflowValue}px;
    `
  }
  return ''
}

const BoxPadding = css`
  @media screen and (max-width: ${UserInterface.breakpoints.smallMobile}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const BoxWrapper = styled(({ custom, children, className, ...rest }) => (
  <Box className={className} custom={custom} {...rest}>
    {children}
  </Box>
))`
  ${({ custom }) => custom && BoxPadding}
`

const BoxOverflow = styled.div<{
  align?: EBoxAlign
  overflowLeft?: boolean
  overflowRight?: boolean
}>`
  height: 100%;
  text-align: ${({ align }) => align};
  ${({ overflowLeft, overflowRight }) =>
    setBoxOverflow({ overflowLeft, overflowRight }, 200)};

  @media screen and (max-width: 1700px) {
    ${({ overflowLeft, overflowRight }) =>
      setBoxOverflow({ overflowLeft, overflowRight }, 150)};
  }
  @media screen and (max-width: 1600px) {
    ${({ overflowLeft, overflowRight }) =>
      setBoxOverflow({ overflowLeft, overflowRight }, 75)};
  }
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    ${({ overflowLeft, overflowRight }) =>
      setBoxOverflow({ overflowLeft, overflowRight }, 0)};
  }
`

/**
 * Component
 */
const BoxCustom: React.FC<IBoxCustom> = ({
  align = EBoxAlign.LEFT,
  children,
  overflowLeft,
  overflowRight,
  overflowVertical = false,
  background,
  width,
  custom = true,
  className,
  ...rest
}) => {
  return (
    <BoxWrapper
      className={classNames(className)}
      width={[1, width]}
      pl={overflowLeft || overflowRight ? [0, '2.5rem'] : '2.5rem'}
      pr={overflowLeft || overflowRight ? [0, '2.5rem'] : '2.5rem'}
      {...(overflowVertical && { pb: ['2.5rem', 0] })}
      // pb={overflowVertical ? ['2.5rem', 0] : '0'}
      custom={custom}
      {...rest}
    >
      <BoxOverflow
        overflowLeft={overflowLeft}
        overflowRight={overflowRight}
        align={align}
      >
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            const props: Record<string, unknown> = {}
            const childProps = child.props as Record<string, unknown>

            if (background === 'blue') {
              switch (getDisplayName(child.type)) {
                case 'Arrow':
                case 'Text':
                case 'BulletList':
                  props.color = childProps.color || 'white'
                  break
                case 'CheckList':
                  props.color = childProps.color || 'white'
                  break
                case 'MiddleTitle':
                case 'SmallTitle':
                case 'SliderTestimony':
                case 'SliderTestimonyCompact':
                  props.color = childProps.color || 'white'
                  break
                case 'Image':
                  props.theme = 'blue'
                  break
                default:
              }
            } else {
              switch (getDisplayName(child.type)) {
                case 'Subtitle':
                  props.color = childProps.color || 'middleGrey'
                  break
                case 'MiddleTitle':
                  props.color = childProps.color || 'extraDarkGrey'
                  break
                default:
              }
            }
            return React.cloneElement(child, props)
          }
          return child
        })}
      </BoxOverflow>
    </BoxWrapper>
  )
}

export default BoxCustom
