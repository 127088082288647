import React, { FC, useContext } from 'react'
import { ButtonLink as ButtonComponent, Link } from '..'
import classNames from 'classnames'
import { TButtonSecondary } from './type'
import { AppContext } from '../../providers/SectionProvider'
import styled from 'styled-components'
import { getSecondaryStyles } from './utils'

const Button = styled(props => <ButtonComponent {...props} />)`
  ${({ sectionBackground, styles }) =>
    sectionBackground &&
    `button {
      color: ${styles.color} !important;
      background-color: ${styles.backgroundColor} !important;
      border: ${styles.border} !important;
      &:hover {
        color: ${styles.hover.color} !important;
        border: ${styles.hover.border} !important;
        background-color: ${styles.hover.backgroundColor} !important;
      }
    }`}}
`

/**
 * Component
 */
const ButtonSecondary: FC<TButtonSecondary> = ({
  align,
  id,
  children,
  className,
  href,
  nofollow,
  target,
  size,
  onClick,
  margin,
}) => {
  const Context = useContext(AppContext)

  if (Context === undefined) {
    return null
  }

  const styles = getSecondaryStyles(Context.sectionBackground)

  return (
    <Link>
      <Button
        id={id}
        sectionBackground={Context.sectionBackground}
        href={href}
        target={target}
        className={classNames('button', 'button--type-link', className)}
        onClick={onClick}
        nofollow={nofollow}
        align={align}
        size={size}
        margin={margin}
        styles={styles}
      >
        {children}
      </Button>
    </Link>
  )
}

ButtonSecondary.displayName = 'ButtonSecondary' // displayName is used by storybook for react-element-to-jsx-string display

export default ButtonSecondary
