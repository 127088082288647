import { EBackground, EColor } from 'src/styles/type'

export const getPrimaryStyles = (sectionBackground: EBackground) => {
  const styles = {
    color: EColor.WHITE,
    backgroundColor: EColor.BLUE,
    border: `2px solid ${EColor.BLUE}`,
    hover: {
      color: EColor.WHITE,
      border: `2px solid ${EColor.BLUE_HOVER}`,
      backgroundColor: EColor.BLUE_HOVER,
    },
  }
  if (sectionBackground === EBackground.BLUE) {
    styles.backgroundColor = EColor.YELLOW
    styles.border = `2px solid ${EColor.YELLOW}`
    styles.hover.backgroundColor = EColor.YELLOW_HOVER
    styles.hover.border = `2px solid ${EColor.YELLOW_HOVER}`
  }

  return styles
}

export const getSecondaryStyles = (sectionBackground: EBackground) => {
  const styles = {
    color: EColor.MIDDLE_GREY,
    backgroundColor: 'transparent',
    border: `2px solid ${EColor.YELLOW}`,
    hover: {
      color: EColor.YELLOW_HOVER,
      border: `2px solid ${EColor.YELLOW_HOVER}`,
      backgroundColor: 'transparent',
    },
  }
  if (sectionBackground === EBackground.BLUE) {
    styles.color = EColor.WHITE
    styles.backgroundColor = EColor.BLUE
    styles.border = `2px solid ${EColor.YELLOW}`
    styles.hover.color = EColor.YELLOW
  }

  return styles
}
