export enum EColor {
  BLUE = '#316BF2',
  BLUE_HOVER = '#0040D7',
  MEDIUM_BLUE = '#1257F6',
  DARK_BLUE = '#011E62',
  EXTRA_DARK_BLUE = '#00174d',
  TURQUOISE = '#00C1A2',
  DARK_TURQUOISE = '#00AA8E',
  YELLOW = '#F6C131',
  YELLOW_HOVER = '#F9AC29',
  RED = '#F36052',
  EXTRA_DARK_GREY = '#333333',
  DARK_GREY = '#999999',
  MIDDLE_GREY = '#626262',
  LIGHT_GREY = '#DEDEDE',
  EXTRA_LIGHT_GREY = '#F5F5F5',
  WHITE = '#FFFFFF',
  PASTEL_BLUE = '#D6E1FC',
  CLEAR_BLUE = '#E6EDFD',
  INTERMEDIATE_BLUE = '#7BA0F6',
  EXTRA_CLEAR_BLUE = '#f9fbff',
  DARK_YELLOW = '#e1ad21',
  ORANGE = '#ea8f25',
  LIGHT_ORANGE = '#efbb7e',
  DARK_ORANGE = '#c77a1f',
  SPECIFIC_BLUE = '#F4F7FE',
}

export type Colors = {
  primary: string
  blue: string
  blueHover: string
  mediumBlue: string
  darkBlue: string
  extraDarkBlue: string

  turquoise: string
  darkTurquoise: string

  yellow: string
  yellowHover: string
  red: string

  extraDarkGrey: string
  darkGrey: string
  middleGrey: string
  lightGrey: string
  extraLightGrey: string

  white: string

  pastelBlue: string
  clearBlue: string
  extraClearBlue: string
  darkYellow: string
  orange: string
  lightOrange: string
  darkOrange: string
  specificBlue: string
}

export enum EFontFamily {
  POPPINS = 'Poppins, Arial, sans-serif',
  ROBOTO = 'Roboto, Arial, sans-serif',
  CAVEAT = 'Caveat, Arial, sans-serif',
}

export type Fonts = {
  family: {
    poppins: string
    roboto: string
    caveat: string
  }
  weight: {
    light: number
    normal: number
    medium: number
    bold: number
  }
}

export enum EFontWeight {
  LIGHT = 200,
  NORMAL = 400,
  MEDIUM = 600,
  BOLD = 700,
}

// TO DO AFTER JSX TO TSX MOVE: Choose beter name for this enum to fit with reality (mobile, tablet, desktop)
export enum EBreakpoint {
  EXTRA_SMALL_MOBILE = '500px',
  SMALL_MOBILE = '900px',
  MOBILE = '1200px',
  TABLE = '1350px',
  LARGE_TABLE = '1500px',
  LARGE_SCREEN = '2000px',
}

export enum ETheme {
  BLUE = 'blue',
  WHITE = 'white',
}

export enum EBackground {
  BLUE = 'blue',
  WHITE = 'white',
  LIGHT_BLUE = 'light-blue',
}
